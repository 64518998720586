<template>
  <NotPermission v-if="!authenticated" />

  <v-container v-else fluid grid-list-lg>
    <Breadcrumbs :routes="[{ name: 'Inicio' }, { name: 'Producto' }, { name: 'Listado' }]" />
    <v-card>
      <v-toolbar color="grey darken-1" dark card>
        <v-toolbar-title>Productos</v-toolbar-title>
        <v-spacer />
        <v-btn :to="{ name: 'CrearProducto' }" color="primary">
        <svg style="width:24px;height:24px; margin-right:10px;" viewBox="0 0 24 24">
              <path fill="currentColor" d="M20 14H14V20H10V14H4V10H10V4H14V10H20V14Z" />
          </svg>
          Agregar Producto
        </v-btn>
      </v-toolbar>
      <v-container fluid grid-list-lg>
        <v-layout row wrap>
          <v-flex md4 sm12>
            <v-text-field
              v-model="search"
              box
              dense
              append-icon="search"
              label="Buscar"
              clearable
              hide-details
            />
          </v-flex>
          <v-flex md3 sm12>
            <v-autocomplete
              v-model="estado"
              :items="estados"
              dense
              box
              clearable
              small-chips
              label="Seleccionar Estado"
              item-text="nombre"
              item-value="id"
            />
          </v-flex>
          <v-flex md2 sm2>
            <v-btn
              :loading="loading4"
              :disabled="loading4"
             color="primary"
              large
              block
              @click="searchData"
            >
              <svg style="width:24px;height:24px; margin-right:10px;" viewBox="0 0 24 24">
            <path fill="currentColor" d="M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z" />
            </svg>
              Buscar
              <template v-slot:loader>
                <span class="custom-loader">
                  <v-icon light>cached</v-icon>
                </span>
              </template>
            </v-btn>
          </v-flex>
          <v-flex md2 sm2>
            <v-btn color="error" large @click="clearSearch" title="Limpiar filtros">
               <v-icon dark style="margin-right:10px;">
                  delete
                </v-icon>
                Limpiar filtros
            </v-btn>
          </v-flex>
          <v-flex xs12>
            <v-data-table
              :headers="[
                { text: 'ID', value: 'id' },
                { text: 'Nombre', value: 'nombre' },
                { text: 'Categoria', value: 'categoria.nombre' },
                { text: 'Estado' },                                
                { text: 'Acciones' }
              ]"
              :items="productos"
              :loading="loading"
              hide-actions
              :pagination.sync="pagination"
              class="elevation-1"
            >
              <tr slot="items" slot-scope="props">
                <td class="px-3">
                  {{ props.item.id }}
                </td>
                <td class="px-3">
                  {{ props.item.nombre }}
                </td>
                <td class="px-3">
                  {{ props.item.categoria.nombre }}
                </td>
                <td class="px-3">
                  <v-chip
                    v-if="props.item.estado === true"
                    small
                    color="primary"
                    text-color="white"
                  >
                    ACTIVO
                  </v-chip>
                  <v-chip v-else-if="props.item.estado === false" small>
                    INACTIVO
                  </v-chip>
                </td>
                <td class="px-3">
                  <v-btn
                    class="ma-0"
                    :to="{ name: 'EditProduct', params: { id: props.item.id } }"
                    small
                    color="success"
                    style="margin-right:10px !important; margin-bottom: 4px !important;"
                  >
                    <svg style="width:24px;height:24px; margin-right: 10px;" viewBox="0 0 24 24">
                        <path fill="currentColor" d="M10 19.11L12.11 17H7V15H14V15.12L16.12 13H7V11H17V12.12L18.24 10.89C18.72 10.41 19.35 10.14 20.04 10.14C20.37 10.14 20.7 10.21 21 10.33V5C21 3.89 20.1 3 19 3H5C3.89 3 3 3.89 3 5V19C3 20.11 3.9 21 5 21H10V19.11M7 7H17V9H7V7M21.7 14.35L20.7 15.35L18.65 13.3L19.65 12.3C19.86 12.09 20.21 12.09 20.42 12.3L21.7 13.58C21.91 13.79 21.91 14.14 21.7 14.35M12 19.94L18.06 13.88L20.11 15.93L14.06 22H12V19.94Z" />
                    </svg>
                    Producto
                  </v-btn>
                    <v-btn
                    class="ma-0"
                     :to="{ name: 'ListadoProductItems', params: { id: props.item.id } }"
                    small
                    color="primary"
                    style="margin-bottom: 4px !important;"
                  >
                  <svg style="width:24px;height:24px;margin-right: 10px;" viewBox="0 0 24 24">
                      <path fill="currentColor" d="M9,5V9H21V5M9,19H21V15H9M9,14H21V10H9M4,9H8V5H4M4,19H8V15H4M4,14H8V10H4V14Z" />
                  </svg>
                    Items
                  </v-btn>
                  <v-btn
                    v-if="userIsAdmin || userIsSupervisor"
                    icon
                    flat
                    @click="openModalDeleteProducto(props.item)"
                    color="error"
                  >
                    <v-icon>
                      delete
                    </v-icon>
                  </v-btn>
                </td>
              </tr>
            </v-data-table>

            <div class="text-xs-center pt-2 md4 lg4">
              <v-pagination
                @input="paginationChangeHandler"
                v-model="pagination.pag1"
                :length="pages"
                :total-visible="5"
                circle
              ></v-pagination>
            </div>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card>
    <ModalDelete @onChange="getData" />
    <ModalItems :productoId="productoId" />
    <ModalDetail :productoId="productoId" :producto="producto" />
  </v-container>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";

import axios from "axios";
import { baseUrlAPI } from "@/config/api";
const HOST = baseUrlAPI;
export default {
  metaInfo() {
    return { title: "Listado de Productos" };
  },

  components: {
    NotPermission: () => import("@/views/errors/NotPermission"),
    Breadcrumbs: () => import("@/components/Breadcrumbs"),
    ModalDelete: () => import("@/views/productos/ModalDeleteProduct"),
    ModalItems: () => import("@/views/productos/ListItems"),
    ModalDetail: () => import("@/views/productos/ModalDetail")
  },

  data() {
    return {
      search: "",
      loading: true,
      estado: "",
      loading4: false,
      pagination: { totalItems: 0, rowsPerPage: 15, pag1: 1 },
      productos: [],
      pages: "",
      total: [],
      productoId: "",
      openItem: false,
      openDetail: false,
      estados: [
        { id: false, nombre: "inactivo" },
        { id: true, nombre: "activo" }
      ]
    };
  },

  computed: {
    ...mapGetters({
      authenticated: "auth/check",
      user: "auth/user",
      userIsAdmin: "auth/userIsAdmin",
      userIsSupervisor: "auth/userIsSupervisor"
    })
  },
  created() {
    this.getData();
  },
  methods: {
    ...mapActions({
      getProductos: "productos/getProductos",
      replaceCurrentProducto: "productos/replaceCurrentProducto",
      replaceShowModalDeleteProducto: "productos/replaceShowModalDeleteProducto",
      replaceShowModalItems: "productos/replaceShowModalItems",
      getItems: "productos/getItems",
      getProducto: "productos/getProducto",
      replaceShowProduct: "productos/replaceShowProduct",
      getCategorias: "categorias/getCategorias",
      getAtributos: "productos/getAtributos",
      getEtiquetas: "productos/getEtiquetas",
      getImages: "productos/getImages",
      getRatings: "ratings/getRatings"
    }),
    clearSearch() {
      this.search = "";
      this.estado = "";
      this.getData();
    },
    async openModalItems(producto) {
      this.productoId = producto.id;
      this.openItem = true;
      await this.getItems({ productoId: producto.id });
      await this.getProducto({ productoId: producto.id });
      this.openItem = false;
      this.replaceShowModalItems({ status: true });
    },
    searchData() {
      this.loading4 = true;
      this.pagination.pag1 = 1;
      if (this.estado == undefined || this.estado == null) {
        this.estado = "";
      }
      if (this.search == undefined || this.search == null) {
        this.search = "";
      }
      axios.get(`${HOST}/productos?search=${this.search}&&estado=${this.estado}`).then(response => {
        this.loading4 = false;
        this.productos = response.data.data;
        this.totalPassengers = response.data.meta.total;
        this.numberOfPages = response.data.meta.last_page;
        this.pages = response.data.meta.last_page;
        this.pagination.pag1 = response.data.meta.current_page;
      });
    },
    paginationChangeHandler(pageNumber) {
      this.pagination.pag1 = pageNumber;
      this.getData();
    },
    openModalDeleteProducto(producto) {
      this.replaceCurrentProducto({ producto });
      this.replaceShowModalDeleteProducto({ status: true });
    },
    getData() {
      let url = `${HOST}/productos?page=${this.pagination.pag1}&search=${this.search}&estado=${this.estado}`;
      axios.get(url).then(response => {
        this.pages = response.data.meta.last_page;
        this.productos = response.data.data;
        this.loading = false;
        this.pagination.totalItems = response.data.meta.total;
        this.pagination.rowsPerPage = response.data.meta.per_page;
        this.pagination.pag1 = response.data.meta.current_page;
        this.pages = response.data.meta.last_page;
      });
    },
    async openDetailProduct(producto) {
      this.openDetail = true;
      this.productoId = producto.id;
      await this.getCategorias();
      await this.getProducto({ productoId: producto.id });
      await this.getAtributos({ productoId: producto.id });
      await this.getEtiquetas({ productoId: producto.id });
      await this.getImages({ productoId: producto.id });
      await this.getRatings({ productoId: producto.id });
      this.openDetail = false;
      this.replaceShowProduct({ status: true });
    }
  }
};
</script>
<style>
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
.v-btn {
  text-transform: none !important;
  font-size: 0.85em;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
